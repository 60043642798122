<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                合同编号：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入发货人"
                                ></el-input>
                            </td>
                            <td style="width: 80px;">
                                公司名称：
                            </td>
                            <td class="td-input">
                                <el-input v-model="searchItem.companyName" size="small" placeholder="请输入公司名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px;">
                                发货地址：
                            </td>
                            <td>
                                <el-input v-model="searchItem.receivingAddress" style="width: 100%;" size="small"
                                          placeholder="请输入发货地址"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-text" style="padding-top: 10px;">
                                发货时间：
                            </td>
                            <td class="td-input" style=" padding-top: 10px;width: 28%">
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 47%"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date"
                                        placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td class="td-text" style="padding-top: 10px;">
                                发货编号：
                            </td>
                            <td class="td-input" style=" padding-top: 10px">
                                <el-input v-model="searchItem.outListNum" size="small" placeholder="请输入发货编号"
                                ></el-input>
                            </td>
                            <td colspan="2" style="padding-top: 10px;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small"
                                           @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh-right" style="margin-left: 5%"
                                           size="small" @click="reset">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                    <a  :href="projectInfoUrl" ref="downProjectInfo"  :download="projectInfoUrl" />
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                v-loading="loading"
                                element-loading-text="拼命加载中"
                                element-loading-spinner="el-icon-loading"
                                element-loading-background="rgba(0, 0, 0, 0.1)"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :row-style="{height:'40px'}"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" align="center"></el-table-column>
                            <el-table-column prop="contractNum" label="合同编号" width="150" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="outListNum" label="发货编号" width="180" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyName" label="公司名称"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="companyType" label="公司类型" width="140" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.companyType == 0">建机厂</span>
                                    <span v-else-if="scope.row.companyType == 1">国企</span>
                                    <span v-else-if="scope.row.companyType == 2">租赁商</span>
                                    <span v-else-if="scope.row.companyType == 3">代理商</span>
                                    <span v-else>集团公司</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="发货时间" width="110" :formatter="changeStyle"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="receivingAddress" label="发货地址" show-overflow-tooltip
                                             align="center"></el-table-column>
                            <el-table-column prop="sendCount" label="发货数量" width="80" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="surplusCount" label="剩余数量" width="80" align="center"
                                             show-overflow-tooltips></el-table-column>
                            <el-table-column prop="sendUser" label="发货人" width="120" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column label="操作" width="150" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <div class="opertion">
                                         <i class="iconfont iconxiangqing" title="详情" style="color:#20a0ff" @click="showOutDepot(scope.row.id,scope.row.contractId)"></i>
                                        <i class="iconfont iconxiangmuxinxi" title="项目信息" style="color:#20a0ff;margin-left:20px" @click="showProjecyInfo(scope.row.id,scope.row.contractId)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                            <!--<el-table-column prop="remark" label="备注"  align="center" show-overflow-tooltip></el-table-column>-->
                            <!--<el-table-column prop="id" label="操作" width="200" align="center">
                                <template slot-scope="scope">
                                    <el-tooltip class="item" effect="light" size="small" content="编辑" placement="top">
                                        <el-button type="info" icon="el-icon-document" circle @click="showDepot(scope.row)"></el-button>
                                    </el-tooltip>
                                </template>
                            </el-table-column>-->
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-info">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    style="margin-top: -90px"
                    center>
                <div class="dialog-body">
                    <div class="basic-info">
                        <div>
                            <table style="text-align: left;width: 100%">
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        公司名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.companyName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">合同号：</td>
                                    <td style="width: 170px">
                                        {{outDepot.contractNum}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">客户类型：</td>
                                    <td style="width: 300px">
                                        {{outDepot.companyType==0?"建机厂":(outDepot.companyType==1?"国企":(outDepot.companyType==2?"租赁商":(outDepot.companyType==3?"代理商":"集团公司")))}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        通知单时间：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.sendNoticeTime}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">塔机数量：</td>
                                    <td style="width: 170px">
                                        {{outDepot.towerCount}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">升降机数量：</td>
                                    <td style="width: 170px">
                                        {{outDepot.lifterCount}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        收货人：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.receivingUser}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">联系电话：</td>
                                    <td style="width: 170px">
                                        {{outDepot.receivingUserTel}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">送货地址：</td>
                                    <td style="width: 170px">
                                        {{outDepot.receivingAddress}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px">
                                        项目名称：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.projectName}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        服务周期(月)：
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.startDate}}~{{outDepot.endDate}}
                                    </td>
                                    <td style="padding: 10px;width: 120px">
                                        设备类型
                                    </td>
                                    <td style="width: 300px;">
                                        {{outDepot.deviceType==0?'普通型':'加强型'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px;width: 120px;">
                                        备注：
                                    </td>
                                    <td style="width: 300px;text-align: left" colspan="5">
                                        {{outDepot.remark}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 92px">功能需求：</td>
                                    <td colspan="5">
                                        <div class="td-body">
                                            <table cellpadding="0" cellspacing="0" style="width: 100%">
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        序号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.index}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;text-overflow:ellipsis;padding: 3px; text-align: center">
                                                        塔机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in towerItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        升降机
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in lifterItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px; text-align: center">
                                                        特殊型号
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        {{item.text}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid #EBEEF8;padding: 3px;text-align: center">
                                                        数量
                                                    </td>
                                                    <td :key="i" v-for="(item,i) in specialItems"
                                                        style="text-align: center;border: 1px solid #EBEEF8;padding: 3px;">
                                                        {{item.quality}}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <template>
                                <el-table
                                        :data="basicTable"
                                        border
                                        style="width: 100%;margin-top: 10px"
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: '41px'}">
                                    <el-table-column type="index" label="序号" width="50px"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="deviceCategory" label="设备类型" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.deviceCategory == 1">升降机</span>
                                            <span v-else>塔机</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="sizeModel" label="规格型号" align="center"></el-table-column>
                                    <el-table-column prop="siteNum" label="现场编号" align="center"></el-table-column>
                                    <el-table-column prop="stringDiameter" label="钢丝绳直径(mm)"
                                                     align="center"></el-table-column>
                                    <el-table-column prop="armLength" label="前臂长度(m)" align="center"></el-table-column>
                                    <el-table-column prop="maxLoad" label="最大载重(T)" align="center"></el-table-column>
                                    <el-table-column prop="deviceType" label="类型" align="center"></el-table-column>
                                    <el-table-column prop="isNeedCard" label="是否需要视频流量卡" align="center">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedCard == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="isNeedModel" align="center" label="是否需要现场调试多塔防碰撞功能">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.isNeedModel == 1">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                            <el-table
                                    :data="numList"
                                    border
                                    :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                    :cell-style="{padding:'0',height: '41px'}"
                                    style="width: 100%;margin-top: 10px;margin-bottom:10px">
                                <el-table-column prop="deviceNum" label="现场编号" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="imeiNum" label="IMEI号" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="simNum" label="基础网卡号1" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="videoCard" label="视频网卡号" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="cockpitVideo" label="驾驶室视频" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="blindVideo" label="盲吊视频" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="otherVideo" label="其他视频" align="center"
                                                 show-overflow-tooltip></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="infoVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                type: 0,
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                showTitle: '',
                towerItems: [],
                deviceNums: [],
                lifterItems: [],
                specialItems: [],
                infoVisible: false,
                outDepot: {
                    receivingAddress: "",
                    receivingUser: "",
                    receivingUserTel: "",
                    chargePolicy: "",
                    companyName: "",
                    companyType: "",
                    contractName: "",
                    contractNum: "",
                    startDate: "",
                    endDate: "",
                    deviceType: "",
                    lifterCount: "",
                    projectName: "",
                    sendNoticeTime: "",
                    sendNoticeNum: "",
                    sendTotalCount: "",
                    specialPolicy: "",
                    towerCount: "",
                    userName: "",
                    remark: ""
                },
                basicTable: [],
                dialogVisible: false,
                dialogVisibles: false,
                totalPage: 30,
                option: {
                    pageIndex: '',
                    pageSize: 15,
                    currentPage: 2
                },
                searchItem: {
                    contractNum: '',
                    companyName: '',
                    receivingAddress: '',
                    startDate: '',
                    endDate: '',
                    outListNum: '',
                    pageSize: 15,
                    pageIndex: 1
                },
                tableData: [],
                numList: [],
                lineHeight: '',
                urlFilePath: process.env.VUE_APP_CURENV,
                projectInfoUrl:'',
                loading: false,
            }
        },
        methods: {
            
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchItem.pageIndex = option;
                this.loading = true;
                this.$api.pageDepotOut(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.loading = false;
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.loading = true;
                this.$api.pageDepotOut(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.loading = false;
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            openDialog(val) {
                this.dialogVisibles = !this.dialogVisibles;
            },
            showOutDepot(id, contractId) {
                this.infoVisible = true;
                this.towerItems = [];
                this.lifterItems = [];
                this.$api.getOutdepotDetail({id: id, contractId: contractId}).then(res => {
                    if (res.code == 200) {
                        this.outDepot = {
                            receivingAddress: res.data.warehouseOutList.receivingAddress,
                            receivingUser: res.data.warehouseOutList.receivingUser,
                            receivingUserTel: res.data.warehouseOutList.receivingUserTel,
                            chargePolicy: res.data.chargePolicy,
                            companyName: res.data.companyName,
                            companyType: res.data.companyType,
                            contractName: res.data.contractName,
                            contractNum: res.data.contractNum,
                            startDate: res.data.startDate,
                            endDate: res.data.endDate,
                            deviceType: res.data.deviceType,
                            lifterCount: res.data.lifterCount,
                            projectName: res.data.projectName,
                            sendNoticeTime: res.data.sendNoticeTime,
                            sendNoticeNum: res.data.sendNoticeNum,
                            sendTotalCount: res.data.sendTotalCount,
                            specialPolicy: res.data.specialPolicy,
                            towerCount: res.data.towerCount,
                            userName: res.data.userName,
                            remark: res.data.warehouseOutList.remark
                        };
                        this.contractFile = {
                            id: res.data.id,
                            projectMsgUrl: res.data.projectMsgUrl,
                            otherAttachUrl: res.data.otherAttachUrl,
                            deviceListUrl: res.data.deviceListUrl,
                        }
                        this.isPass = res.data.auditState;
                        this.basicTable = res.data.deviceList;
                        this.showTitle = '《' + res.data.contractNum + '》详情信息'
                        var towerItemsStr = res.data.towerMap;
                        if (towerItemsStr) {
                            var funcItem = {};
                            var index = 1
                            for (var i in towerItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.index = index;
                                funcItem.quality = towerItemsStr[i];
                                this.towerItems.push(funcItem);
                                index = index + 1
                            }
                        }
                        var lifterItemsStr = res.data.lifterMap;
                        if (lifterItemsStr) {
                            var funcItem = {};
                            for (var i in lifterItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = lifterItemsStr[i];
                                this.lifterItems.push(funcItem);
                            }
                        }
                        var specialItemsStr = res.data.specialMap;
                        if (specialItemsStr) {
                            var funcItem = {};
                            for (var i in specialItemsStr) {
                                funcItem = {text: '', quality: 0}
                                funcItem.text = i;
                                funcItem.quality = specialItemsStr[i];
                                this.specialItems.push(funcItem);
                            }
                        }
                        this.numList = res.data.warehouseDeviceList;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            showProjecyInfo(id, contractId) {
                 this.$api.getOutdepotDetail({id: id, contractId: contractId}).then(res => {
                    if (res.code == 200) {
                        if(res.data.projectMsgUrl == "")
                        {
                            this.$message.error("未上传项目信息");
                        }
                        else{
                            this.projectInfoUrl = this.urlFilePath + 'erp' +  res.data.projectMsgUrl;
                            this.$refs.downProjectInfo.click()
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                 });
            },
            searchBtn() {
                this.pageChange(1)
            },
            reset() {
                this.searchItem = {
                    contractNum: '',
                    companyName: '',
                    receivingAddress: '',
                    startDate: '',
                    endDate: '',
                    outListNum: '',
                    pageSize: 15,
                    pageIndex: 1,
                }
                this.pageChange(1);
            },
            changeStyle(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date

            }
        },
        created() {
            this.searchBtn();
            this.tableHeight = (window.innerHeight) * 0.71
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }

    }
</script>

<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        color: #20a0ff;
        margin-right: 20px;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    .addNum {
        margin-top: 10px;
        width: 100%;
    }

    .input {
        display: flex;
        width: 100%;
    }

    .button {

        margin: 10px 0 0 0;
        text-align: center;
    }

    .input-style {
        display: flex;
        width: 100%;
        margin-top: 10px;
    }

    .text-show {
        padding: 0.5% 1%;
    }

    .button-style {
        padding: 0 1%;
    }

    .input-text {
        width: 180px;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 13%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .td-body {
        padding: 10px 0;
        white-space: nowrap;
        overflow-x: scroll;
        width: 1180px;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .td-input {
        width: 28%;
    }

    .dialog-body {
        max-height: 650px;
        overflow-y: auto;
    }
</style>
